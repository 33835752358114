import { logos } from "../images/svgs";

export const skillCategories = [
  {
    key: 0,
    category: "・Web",
    list: [
      { name: "HTML5" },
      { name: "CSS/SASS" },
      { name: "JavaScript", logo: logos.javascript },
      { name: "Bootstrap", logo: logos.bootstrap },
      { name: "jQuery" },
      { name: "React.js", logo: logos.react },
      { name: "Next.js", logo: logos.nexjs },
      { name: "NPM/Yarn" },
      { name: "JSX/TSX" },
      { name: "Visual Studio Code", logo: logos.visual_studio },
      { name: "Visual Studio" },
    ],
  },
  {
    key: 1,
    category: "・Mobile",
    list: [
      { name: "React Native", logo: logos.react },
      { name: "Expo" },
      { name: "Java" },
      { name: "Swift" },
      { name: "SQLite" },
      { name: "XCode" },
      { name: "Android Studio" },
    ],
  },
  {
    key: 2,
    category: "・Backend",
    list: [
      { name: "Node.js", logo: logos.node },
      { name: "Express.js" },
      { name: "JWT/Passport" },
      { name: "Rest API" },
    ],
  },
  {
    key: 3,
    category: "・Database",
    list: [
      { name: "Postgres" },
      { name: "SQL Server" },
      { name: "MongoDB/Mongoose", logo: logos.mongodb },
      { name: "MySQL" },
    ],
  },
  {
    key: 4,
    category: "・Others",
    list: [
      { name: "TypeScript", logo: logos.typescript },
      { name: "Docker/Docker-compose" },
      { name: "Firebase", logo: logos.firebase },
      { name: "Git/Git flow", logo: logos.git },
      { name: "Github/Bitbucket", logo: logos.github },
      { name: "Bash" },
      { name: "Microsoft Word/Excel/Macro" },
      { name: "WordPress" },
    ],
  },
  {
    key: 5,
    category: "・Communication",
    list: [{ name: "Japanese - Native" }],
  },
];
