import React, { useState } from "react";
import { Container, Row } from "reactstrap";
import { useSpring, animated } from "react-spring";
import { Element } from "react-scroll";

const About = () => {
  const [otherInfoOpen, toggleOtherInfo] = useState(false);

  const moveLeft = useSpring({
    to: { opacity: 1, transform: "translateX(0px)" },
    from: {
      opacity: 0,
      transform: "translateX(150px)",
    },
    delay: 200,
    config: { friction: 40 },
  });
  return (
    <Container id="about">
      <Element name="about" />
      <Row>
        <h2 className="Page-header">About myself</h2>
      </Row>
      <Row className="mb-2">
        <div className="col-md-6">
          <p>
            Hi, my name is <strong>Steve</strong>. I am a software engineer who
            loves to build applications to solve problems and make our lives
            more fun!
            <br />
            <br />
            Having studied mobile app development myself in the past, I attended
            a coding school where I learned full stack development.
            Collaborating with the instructors and classmates were a lot of fun,
            and I am happy that I got the perfect scores for all quizzes and
            assignments during the eight-month program. It has been a great joy
            to learn and use the technologies I learn. I would love to continue
            learning new skills.
          </p>
          {otherInfoOpen ? (
            <div className="sm-5">
              <h5
                className="my-3"
                onClick={() => toggleOtherInfo(!otherInfoOpen)}
              >
                Life and Future{" "}
                <i className="fa fa-caret-up" aria-hidden="true" />
              </h5>
              <div className="ms-3">
                <strong>My life</strong>: I had grown up in Japan, moved to the
                U.S. and never stopped exploring and learning new skills and
                perspectives since then. Most recently being an instructor, I
                experimented and used many online education services, which
                enhanced our learning experiences. I am always fascinated by the
                power of technologies!
                <br />
                <br />
                <strong>Future</strong>: Currently looking for an opportunity to
                apply my skills for challenging projects. Please connect with me
                if you are interested in a user-focused and inquisitive
                programmer.
              </div>
            </div>
          ) : (
            <h5
              className="mt-3 mb-5"
              onClick={() => toggleOtherInfo(!otherInfoOpen)}
            >
              Life and Future{" "}
              <i className="fa fa-caret-down" aria-hidden="true" />
            </h5>
          )}
        </div>
        <div className="col-md-5">
          <Row>
            <h4>Quick Contact Info</h4>
            <p>
              <i className="fa fa-envelope" aria-hidden="true" /> :{" "}
              <a href="mailto:steve.hayato.m@gmail.com">Email</a>
            </p>
            <p>
              <i className="fa fa-linkedin" aria-hidden="true" /> :{" "}
              <a
                href="https://www.linkedin.com/in/steve-moritani/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>{" "}
            </p>
            <p>
              <i className="fa fa-github" aria-hidden="true" /> :{" "}
              <a
                href="https://github.com/hayato1234/"
                target="_blank"
                rel="noreferrer"
              >
                Github
              </a>
            </p>
          </Row>
          <Row>
            <animated.div style={moveLeft}>
              <a
                href="https://github.com/hayato1234/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ width: "350px", height: "auto" }}
                  src="https://github-readme-stats.vercel.app/api?username=hayato1234"
                  alt="Github icon"
                />
              </a>
            </animated.div>
          </Row>
        </div>
      </Row>
    </Container>
  );
};

export default About;
